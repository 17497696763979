<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="所属区域" prop="areaDeptId">
								<a-select v-model="queryParam.areaDeptId" @change="handleChange" placeholder="请选择所属区域" allow-clear>
									<a-select-option :value="region.id" v-for="(region,index) in regionLists" :key="index">
										{{region.deptName}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="所属团队" prop="teamDeptId">
								<a-select v-model="queryParam.teamDeptId" @change="handleChangeTeam" placeholder="请选择团队" allow-clear>
									<a-select-option :value="team.id" v-for="(team,index) in teamList" :key="index">
										{{team.label}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="所属坐席" prop="userId">
								<a-select v-model="queryParam.userId" placeholder="所属坐席" allow-clear>
									<a-select-option :value="service.id" v-for="(service,index) in serviceList" :key="index">
										{{service.nickName}}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="拨打时长" prop="time">
								<a-input v-model="queryParam.minCallTime" type="number" placeholder="请输入" style="width:30%"></a-input>
								<span style="margin: 0 20px;">----</span>
								<a-input v-model="queryParam.maxCallTime" type="number" placeholder="请输入" style="width:30%"></a-input>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="接通率" prop="userId">
								<a-input v-model="queryParam.minDialingRate" type="number" placeholder="请输入"
									style="width:30%"></a-input>
								<span style="margin: 0 20px;">----</span>
								<a-input v-model="queryParam.maxDialingRate" type="number" placeholder="请输入"
									style="width:30%"></a-input>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="统计时间" prop="userId">
								<a-range-picker @change="onChangeTimes" v-model="selecttime" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 6 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<a-button type="primary" @click="handleExport" v-hasPermi="['report:sales:export']">导出</a-button>
				<!-- <table-setting :style="{float: 'right'}" :table-size.sync="tableSize" v-model="columns"
					:refresh-loading="loading" @refresh="getList" /> -->
			</div>
			<!-- 增加修改 -->
			<!-- <create-form ref="createForm" @ok="getList" /> -->
			<!-- 数据展示 -->
			<!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
			<a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:pagination="false" :bordered="tableBordered">
				<span slot="createTime" slot-scope="text, record">
					{{ parseTime(record.createTime) }}
				</span>
				<span slot="dialingRate" slot-scope="text, record">
					{{record.dialingRate}}%
				</span>
				<span slot="callDuration" slot-scope="text, record">
					{{convertSecondsToHMS(record.callDuration)}}
				</span>
				<span slot="averageDuration" slot-scope="text, record">
					{{convertSecondsToHMS(record.averageDuration)}}
				</span>
				<span slot="operation" slot-scope="text, record">
					<a-divider type="vertical" v-hasPermi="['report:sales:edit']" />
					<a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['report:sales:edit']">
						<a-icon type="edit" />修改
					</a>
					<a-divider type="vertical" v-hasPermi="['report:sales:remove']" />
					<a @click="handleDelete(record)" v-hasPermi="['report:sales:remove']">
						<a-icon type="delete" />删除
					</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		pageSales,
		listSales,
		delSales,
		bankdata,
		regionList,
		areaTeam,
		ByDeptIds,
		reportForms
	} from '@/api/report/sales'
	// import CreateForm from './modules/CreateForm'
	import moment from 'moment'
	import {
		tableMixin
	} from '@/store/table-mixin'

	export default {
		name: 'Sales',
		components: {
			// CreateForm
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					areaDeptId: undefined,
					teamDeptId: undefined,
					userId: undefined,
					time: null,
					bankdataId: undefined,
					minCallTime: undefined,
					maxCallTime: undefined,
					minDialingRate: undefined,
					maxDialingRate: undefined,
					createBeginTime: undefined,
					createEndTime: undefined,
					pageNum: 1,
					pageSize: 10
				},
				columns: [{
						title: '名次',
						ellipsis: true,
						width: 80,
						customRender: function(text, record, index) {
							return index + 1
						}
					}, {
						title: '团队',
						dataIndex: 'teamName',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '坐席',
						width: 200,
						dataIndex: 'userName',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '接通率',
						width: 150,
						dataIndex: 'dialingRate',
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'dialingRate'
						},
					},
					{
						title: '通话时长',
						width: 150,
						dataIndex: 'callDuration',
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'callDuration'
						},
					},
					{
						title: '通话次数',
						width: 150,
						dataIndex: 'callNum',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '接通次数',
						width: 150,
						dataIndex: 'connectNum',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '平均通话时长',
						width: 150,
						dataIndex: 'averageDuration',
						ellipsis: true,
						align: 'center',
						scopedSlots: {
							customRender: 'averageDuration'
						},
					},
				],
				bankList: [], //银行列表
				regionLists: [], //区域列表
				teamList: [],
				serviceList: [], //
				selecttime: undefined,

			}
		},
		filters: {},
		created() {
			let date = moment()
			let newDate = date.format('YYYY-MM-DD')
			this.selecttime = [newDate + ' 00:00:00', newDate + ' 23:59:59']
			this.queryParam.createBeginTime = newDate + ' 00:00:00';
			this.queryParam.createEndTime = newDate + ' 23:59:59';
			this.getList();
			this.getbankdata();
			this.getRegionList()
		},
		computed: {},
		watch: {},
		methods: {
			// 选择统计时间
			onChangeTimes(e) {
				this.queryParam.createBeginTime = e[0].format('YYYY-MM-DD') + ' 00:00:00'
				this.queryParam.createEndTime = e[1].format('YYYY-MM-DD') + ' 23:59:59'
			},
			// 区域列表
			getRegionList() {
				regionList().then(res => {
					if (res.success) {
						console.log('区域列表', res);
						this.regionLists = res.data;
					}
				})
			},
			getAreaTeam() {
				areaTeam({
					parentId: this.queryParam.areaDeptId
				}).then(res => {
					if (res.success) {
						console.log('团队列表', res);
						this.teamList = res.data;
					}
				})
			},
			getByDeptIds() {
				ByDeptIds({
					deptId: this.queryParam.teamDeptId,
					roleCode: 'xs'
				}).then(res => {
					console.log('人员列表', res);
					this.serviceList = res.data;
				})
			},
			// 银行列表
			getbankdata() {
				bankdata().then(res => {
					if (res.success) {
						console.log('银行列表', res);
						this.bankList = res.data;
					}
				})
			},
			handleChange(e) {
				this.queryParam.teamDeptId = undefined;
				this.queryParam.userId = undefined;
				if (e) {
					this.queryParam.areaDeptId = e;
					this.getAreaTeam();
				}
			},
			handleChangeTeam(e) {
				console.log(e, '团队')
				this.queryParam.userId = undefined;
				if (e) {
					this.queryParam.teamDeptId = e;
					this.getByDeptIds()
				}
			},
			/** 查询销售报列表 */
			getList() {
				this.loading = true
				reportForms(this.queryParam).then(response => {
					console.log('报表列表', response);
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
				})
			},

			/** 搜索按钮操作 */
			handleQuery() {
				if (this.queryParam.minCallTime < 0) {
					this.$message.error('拨打时长开始泛微不能小于0');
					return false;
				}
				if (this.queryParam.maxCallTime < 0) {
					this.$message.error('拨打时长结束泛微不能小于0');
					return false;
				}
				if (this.queryParam.minDialingRate < 0) {
					this.$message.error('接通率开始泛微不能小于0');
					return false;
				}
				if (this.queryParam.maxDialingRate < 0) {
					this.$message.error('接通率开始泛微不能小于0');
					return false;
				}
				if (this.queryParam.maxCallTime - this.queryParam.minCallTime < 0) {
					this.$message.error('请输入正确的拨打时长范围');
					return false;
				}
				if (this.queryParam.maxDialingRate - this.queryParam.minDialingRate < 0) {
					this.$message.error('请输入正确的接通率范围-');
					return false;
				}
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					areaDeptId: undefined,
					teamDeptId: undefined,
					userId: undefined,
					bankdataId: undefined,
					minCallTime: undefined,
					maxCallTime: undefined,
					minDialingRate: undefined,
					maxDialingRate: undefined,
					createBeginTime: undefined,
					createEndTime: undefined,
					pageNum: 1,
					pageSize: 10
				}
				let date = moment()
				let newDate = date.format('YYYY-MM-DD')
				this.selecttime = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"id": id
						}))
						return delSales(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
						that.ids = []
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('call/call-record/exportReportForms', {
							...that.queryParam
						}, `录音记录报表_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			},
			convertSecondsToHMS(seconds) {
				var hours = Math.floor(seconds / 3600);
				var minutes = Math.floor((seconds % 3600) / 60);
				var remainingSeconds = seconds % 60;
				return (hours > 0 ? hours + "时" : '') + (minutes > 0 ? minutes + "分" : '') + remainingSeconds + "秒";
			}
		}
	}
</script>